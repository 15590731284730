let awsRegion = 'us-west-2';

export default {
 api_urls: {
   reporting: 'https://mwt0tyv101.execute-api.us-west-2.amazonaws.com/api',
   geojson: 'https://5pl8obqz5k.execute-api.us-west-2.amazonaws.com/api',
   overlays: 'https://ozivcfrznk.execute-api.us-west-2.amazonaws.com/api',
 },
 google_api_url: 'AIzaSyDI0B09O3FFEn1-usWMdtJAzat9O7SbvR4',
 main_company_term_id: 6,
 crm_base_url: 'https://crm.dev.gromit.forwoodsafety.com',
 forwood_id_url: 'https://id.dev.gromit.forwoodsafety.com?redirect_uri=https://geoeditor.dev.gromit.forwoodsafety.com',
 custom_tile_url: 'https://mapoverlays.geoeditor.dev.gromit.forwoodsafety.com',
 Auth: {
   userPoolId: 'us-west-2_lFldD5qFp',
   userPoolWebClientId: 'j3rhdtf22b4ch2gsr0q8n5tjm',
   cookieStorage: {
     domain: '.dev.gromit.forwoodsafety.com',
     secure: true,
   },
 },
 intercom_enabled: 'False',
 env: 'dev'
};
